.Contact-main {
    background-color: #FFB1A3;
}

.Contact-content {
    width: 50%;
    margin: auto;
    padding-bottom: 2%;
    font-size: 1.5vmax;

    text-align: center;
}

#Contact-img {
    display: block;
    margin: auto;
    padding-bottom: 2%;
    width: 300px;
}

#Contact-info-text, #Contact-contact-me {
    font-size: 1.25vmax;
    margin: 0;
}

@media (max-width: 500px) {
    .Contact-content { width: 90%; font-size: 20px; }
    #Contact-info-text, #Contact-contact-me { font-size: 16px; }
    #Contact-img { width: 70%; }
}
