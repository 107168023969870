/* ----- ANIMATIONS ----- */
@keyframes fadeInDesktop {
    0% { padding-top: 25%; opacity: 0; }
    100% { padding-top: 2%; opacity: 1; }
}

@keyframes fadeInPhone {
    0% { padding-top: 50%; opacity: 0; }
    100% { padding-top: 20%; opacity: 1; }
}


/* ----- LANDING PAGE ----- */
#Home-landing-page {
    height: 90vh;
    /* background: url('../../assets/background.jpg'); */
    background: url('../../assets/background_new.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    background-attachment: fixed;
}

#Home-landing-page-content {
    padding: 2% 0 0 7%;
    animation-name: fadeInDesktop;
    animation-duration: 1.7s;
}

#Home-landing-page-content-title {
    width: 70%;
    color: purple;

    font-weight: bold;
    font-size: 5vmax;
    line-height: 6vmax;
}

#Home-landing-page-content-text {
    width: 60%;
    color: #4C535B;
    font-size: 2vmax;
}


.Home-text {
    margin: 1% 5%;
    color: #4B545A;
    
    font-size: 1.75vmax;
    font-style: italic;
}




.Home-marquee {
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

.Home-marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}

@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

@media (max-width: 500px) {
    .Home-text { margin: 10%; }
    #Home-landing-page { background-position: top left; }
    #Home-landing-page-content { padding: 20% 5% 0; animation-name: fadeInPhone; }
    #Home-landing-page-content-title { text-align: center; margin: auto; }
    #Home-landing-page-content-text { font-size: 1.25em; width: unset; text-align: center; }
    .Home-text { font-size: 1.75vmax; }
    #Home-logo-img { width: 50%; display: block; margin: auto; }
}

@media (max-width: 900px) and (orientation: landscape)  {
    #Home-landing-page-content-title { font-size: 3em; }
}
