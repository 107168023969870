.BoxRow-boxes {
    width: 100%;
    font-size: 2vmax;
}

.BoxRow-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 5%;
    text-decoration: none;
}
