/* ----- ANIMATIONS ----- */
@keyframes fadeIn {
    0% { padding-left: 10%; opacity: 0; }
    100% { padding-left: 0; opacity: 1; }
}

.HeadLine {
    margin: 100px 0 50px 0;
    width: 100%;
    color: black;
    animation-name: fadeIn;
    animation-duration: 1.5s;
}

.HeadLine p {
    font-size: 8vmax;
    line-height: 0;
    text-align: center;
}

@media (max-width: 500px) {
    .HeadLine { margin: 100px 0 50px 0; }
    .HeadLine p { font-size: 5vmax; }
}
