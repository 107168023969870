#BackArrow-holder {
    width: 100%;
    margin: auto;
    display: flex;
}

#BackArrow-back {
    margin: auto;
    text-align: center;
}

#BackArrow-a {
    margin: auto;
    text-align: center;
    text-align: center;
    text-decoration: none;
    font-size: 1.25vmax;

    background: white;
    padding: 10px 30px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
    transition: .25s;
}

#BackArrow-a:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#BackArrow-a:link,#BackArrow-a:visited, #BackArrow-a:hover,#BackArrow-a:active {
    color:black;
}


@media (max-width: 500px) {
    #BackArrow-a {
        padding: 10px 30px;
        font-size: 2vmax;
    }
}
