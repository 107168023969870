.Box-row a {
    text-decoration: none;
}

.Box-row {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 4px;

    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    position: relative;
    width: calc(100% / 3 - 8px);
    padding-top: calc(100% / 4); /* change 4 to 3 for 1:1 aspect ratio */
}

.Box-row .Box-text {
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Box-row p {
    background: white;
    color: black;
    padding: 20px;
    font-size: 2vmax;
    text-align: center;
    font-weight: bold;

    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Box-row .Box-on-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: grey;
    opacity: 0;
    transition: .3s;
    border-radius: 10px;
}

.Box-row:hover .Box-on-hover {
    opacity: .3;
}

@media (max-width: 1100px) {
    .Box-row { width: 100%; padding-top: 40%; }
}
