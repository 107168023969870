.Footer-footer {
    width: 100%;
    margin: 10px 0;
    font-size: 1vmax;
    color: #524B47;
    padding-top: 10px;
}

#Footer-p {
    text-align: center;
}

#Footer-logo-img {
    width: 250px;
    display: block;
    margin: auto;
}

@media (max-width: 500px) {
    .Footer-footer { font-size: 1.25vmax; }
    #Footer-logo-img { width: 150px; }
}
