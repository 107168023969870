.MiddleTabs-content {
    width: 70%;
    margin: auto;
    /* padding: 3% 5%; */
    font-size: 1.75vmax;
    /* hyphens: auto; */

    /* background: #9B9DEB;
    color: white;
    border-radius: 10px; */
}

.st {
    text-align: center;
}

@media (max-width: 500px) {
    .MiddleTabs-content { width: 90%; font-size: 20px; }
}
