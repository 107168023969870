/* Card Holder */
.book_boxes {
    width: 90%;
    margin: auto;
}

/* Card */
.Book-box {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    color: black;
    transition: .25s;
    
    border-radius: 10px;
    background: #f8d6b8;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.Book-text {
    width: 45%;
    margin: auto;
    font-size: 1.5vmax;
    display: flex;
    justify-content: center;
    text-align: center;
}

.Book-img-holder {
    width: 50%;
}

.Book-img2 {
    width: 100%;
    position: relative;
    margin: auto;
}

@media (max-width: 500px) {
    .Book-box { width: 100%; }
    .Book-img-holder { width: 100%; }
    .Book-text { font-size: 2vmax; width: 90%; margin-bottom: 15px; }
    .Book-box { flex-wrap: wrap; }
}
