@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

#Header-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
}

/* MOBILE HEADER */
#Header-open {
    color: #222222;
    font-weight: bold;
    font-size:25px;
    cursor:pointer;
    padding-left: 10%;
}

#Header-overlay { 
    height: 100%; 
    width: 0; 
    position: fixed; 
    z-index: 1; 
    top: 0; 
    left: 0; 
    background: #FAFAFA;
    overflow-x: hidden;
    transition: 0.3s;

    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
} 

#Header-links {
    position: relative; 
    width: 80%;
    padding-top: 10%;
    padding-left: 10%;
    text-decoration: none;
}

#Header-close {
    width: 60%;
    height: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 25px; 
    color: #222222;
    cursor: pointer;
    text-decoration: none;
}

.Header-link {
    padding: 10px;
    margin: 5px 0;
    font-size: 35px; 
    color: #222222; 
    display: block;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: .3s;
    border-radius: 10px;
}

.Header-link:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media (max-width: 500px) {
    #Header-open { display: block; }
    #Header-links { width: 80%; }
    .Header-link { font-size: 25px; }
    
}
